.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #ffffff;
	background-position: center center;
	background-repeat: no-repeat;
	/* background-image: url(../images/icons/preloader.gif); */
	overflow: hidden;
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #050424 !important;
	z-index: 999
}
.preloader img {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 90px;
	height: auto;
	margin-left: -44px;
	margin-top: -35px
}
.loader-circle {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 1px rgba(255,255,255,.1);
	margin-left: -60px;
	margin-top: -60px
}
.loader-line-mask {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 60px;
	height: 120px;
	margin-left: -60px;
	margin-top: -60px;
	overflow: hidden;
	-webkit-transform-origin: 60px 60px;
	-moz-transform-origin: 60px 60px;
	-ms-transform-origin: 60px 60px;
	-o-transform-origin: 60px 60px;
	transform-origin: 60px 60px;
	-webkit-mask-image: -webkit-linear-gradient(top, #000, rgba(0,0,0,0));
	-webkit-animation: rotate 1.2s infinite linear;
	-moz-animation: rotate 1.2s infinite linear;
	-o-animation: rotate 1.2s infinite linear;
	animation: rotate 1.2s infinite linear
}
.loader-line-mask .loader-line {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 1px rgba(255,255,255,.5)
}
.matic {
	position: absolute;
	bottom: 20px;
	right: 70px
}
@-webkit-keyframes rotate {
0% {
-webkit-transform:rotate(0)
}
100% {
-webkit-transform:rotate(360deg)
}
}
@-moz-keyframes rotate {
0% {
-moz-transform:rotate(0)
}
100% {
-moz-transform:rotate(360deg)
}
}
@-o-keyframes rotate {
0% {
-o-transform:rotate(0)
}
100% {
-o-transform:rotate(360deg)
}
}
@keyframes rotate {
0% {
-webkit-transform:rotate(0);
-moz-transform:rotate(0);
-ms-transform:rotate(0);
transform:rotate(0)
}
100% {
-webkit-transform:rotate(360deg);
-moz-transform:rotate(360deg);
-ms-transform:rotate(360deg);
transform:rotate(360deg)
}
}
@-webkit-keyframes fade {
0% {
opacity:1
}
50% {
opacity:.25
}
}
@-moz-keyframes fade {
0% {
opacity:1
}
50% {
opacity:.25
}
}
@-o-keyframes fade {
0% {
opacity:1
}
50% {
opacity:.25
}
}
@keyframes fade {
0% {
opacity:1
}
50% {
opacity:.25
}
}
@-webkit-keyframes fade-in {
0% {
opacity:0
}
100% {
opacity:1
}
}
@-moz-keyframes fade-in {
0% {
opacity:0
}
100% {
opacity:1
}
}
@-o-keyframes fade-in {
0% {
opacity:0
}
100% {
opacity:1
}
}
@keyframes fade-in {
0% {
opacity:0
}
100% {
opacity:1
}
}


