/* @import "./assets/styles/dist/skeletabs.css"; */
@import "./assets/styles/dist/skeletabs.min.css";
@import "./assets/styles/header.css";
@import "./assets/styles/loader.css";
@import "./assets/styles/main.css";
@import "./assets/styles/bootstrap.min.css";
@import "./assets/styles/animatedCss.css";

/* @import "./assets/styles/owl.carousel.css";
@import "./assets/styles/plugins.css";
@import "./assets/styles/site-main.css";
@import "./assets/styles/stellarnav.min.css";
@import "./assets/styles/themify-icons.css";
@import "./assets/styles/bootstrap.min.css"; */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.form-control {
  border-radius: 50px !important;
  padding: 15px 20px !important;
  /* margin-bottom: 25px; */
  line-height: 1.5;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  background-clip: padding-box;
  border: 1 px solid #4f4d5a;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px !important;
}

.form-control#name {
  text-transform: unset;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #b6afaf;
}

.counter_sec {
  width: 100%;
  z-index: 99;
  position: relative;
  background: #f5f5f5;
  background-size: cover;
  padding: 60px 0 80px;
}

.counter_sec_ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.counter_number {
  font-size: 50px;
  color: #191952;
  border-left: 5px solid #c62028;
  font-weight: 600;
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
}

.counter_title {
  font-size: 20px;
  color: #c62028;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 15px;

}

.counter_title span {
  font-size: 22px;
}

.latestBlog {
  position: relative;
  z-index: 9;
}

@media (max-width: 768px) {
  .funfact {
    margin-bottom: 40px;
  }

  .counter_sec {
    padding: 50px 0 40px 0;
  }

  .counder_box {
    margin-bottom: 10px;
    width: 50%;
  }

  .counter_number {
    text-align: left;
    border-left: 5px solid #c62028;
    padding-left: 15px;
  }

  .counter_title {
    text-align: left;
    padding-left: 15px;
  }

  .underline {
    margin: 30px auto;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.b12 {
  border-radius: 12px !important
}


/* Phone input */

.PhoneInputCountry {
  border-radius: 50px !important;
  padding: 15px 20px !important;
  line-height: 1.5;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 12px;
}


table, td, th {
  border: none !important;
  border-width: 0px !important;
  border-top: none !important;
}

.PhoneInputInput {
  border-radius: 50px !important;
  padding: 15px 20px !important;
  line-height: 1.5;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 12px;
}

.PhoneInputCountry:focus {
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.PhoneInputInput:focus {
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}