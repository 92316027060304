body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table, td, th, tbody {
  border: none !important;
  border-width: 0px !important;
  border-top: none !important;
}

.table-bordered>:not(caption)>* {
  border-width: 0px !important;
}