.header_section {
  width: 100%;
  border-bottom: #ebebeb solid 1px;
  position: absolute;
  background: #fff;
  z-index: 999 !important;
  max-height: 101px;
  display: flex;
  justify-content: center;
}
.headerContainer {
  max-width: 90%;
  padding: 0 !important;
}
.header_outer {
  width: 100%;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.main_logo {
  width: 100%;
}
.main_logo img {
  max-width: 60%;
  max-height: 51px;
}

.menupart {
  width: 100% !important;
  height: 100%;
  display: grid;
  align-items: center;
}
.main-nav {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.menu_active a {
  color: #ce2e33;
  /* border-bottom: #ce2e33 solid 2px !important; */
}
.stellarnav {
  display: flex;
  list-style: none;
  align-items: center;
  margin-bottom: 0;
  justify-content: flex-end;
}
.stellarnav-mobile {
  color: #fff;
  display: flex;
  list-style: none;
  align-items: center;
  margin-bottom: 0;
  justify-content: flex-end;
  background-color: transparent;
}
.mobile-nav {
}
.mobile-nav-container {
  padding: 0px !important;
  position: fixed !important;
  top: 0 !important;
  width: 90% !important;
  height: 100vh !important;
  background-color: #1f1e57 !important;
  z-index: 99 !important;
  visibility: hidden;
  transition: all 0.5s ease 0s;
}
a {
  text-decoration: none !important;
}
.stellarnav li {
  display: inline-block;
  margin-left: 25px;
  /* border-bottom: transparent solid 2px; */
}
.stellarnav li a {
  color: #000;
  font-size: clamp(10px, 1vw, 15px);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  transition: all 0.3s ease-out;
}
.stellarnav > li > a {
  padding: 13px 0px 0px;
  text-transform: uppercase;
}
.stellarnav > li:hover {
  color: #ce2e33 !important;
  border-bottom: #ce2e33 solid 2px;
}
.stellarnav > li > a:hover {
  color: #ce2e33 !important;
}
.mobile-menu-icon {
  display: none;
}
@media screen and (max-width: 876px) {
  .log {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .stellarnav {
    display: none;
  }
  .mobile-nav-container {
    visibility: visible;
    right: 0px !important;
    transition: all 0.3s ease-in !important;
  }
  .stellarnav-mobile {
    /* position: absolute; */
    display: block;
    list-style: none;
    background-color: transparent;
    /* right: 0; */
    /* top: 20px; */
    width: 100%;
  }

  .stellarnav-mobile .dropdown-toggle {
    color: white;
    font-size: 15px !important;
    margin-left: 0 !important;
  }
  .stellarnav-mobile li a,
  .dropdown {
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
  }
  .stellarnav-mobile li {
    text-align: left;
    /* border-bottom: 1px solid silver; */
    padding: 10px;
  }
  .menu_active {
    border-bottom: none !important;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: black;
    background-color: transparent;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
    z-index: 100;
  }
  .extra-text {
    margin-top: auto;
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    text-transform: uppercase;
  }
}

.fullWidth {
  width: 100% !important;
}
.social-buttons {
  padding: 0;
  display: flex;
  width: 100%;
}
